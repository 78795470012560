import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,
    deleted: `deleted`,
    restore_confirm: `restore(confirm)`,
    restore_stop: `restore(restore)`,

    //= table
    edit_table_settings: `edit table settings`,
    no: `no`,
    created_date: `created`,
    group: `group`,
    code: `code`,
    code_name: `code name`,
    total_users: `users`,
    users_confirm: `confirm`,
    users_reject: `reject`,
    users_ban: `ban`,
    users_delete: `delete`,
    agent_id: `agent id`,
    agent_name: `agent name`,
    bank_name: `bank name`,
    bank_number: `bank number`,
    bank_account: `bank account`,
    bank_type: `bank type`,
    status: `status`,
    updated_date: `updated`,
    deleted_date: `deleted`,

    //= create code
    create_code: `create code`,
    agent: `agent`,
    deposit_name: `deposit name`,
    code: `code`,
    code_name: `code name`,
    active: `active`,
    inactive: `inactive`,
    create: `create`,
    select_name: `select name`,

    // modify code
    modify_code: `modify code`,
    modify: `modify`
  }
}
