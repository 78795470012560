<template>
  <div class="wrap__contents codes-inner">
    <div class="toolbar">
      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :date-by="dateBy"
          :filter-by="filterBy"
          @trigger="$_trigger"
          @refresh="search"
          @clear="search"
        />
      </div>

      <div class="toolbar__items button">
        <button
          class="btn--create"
          @click="modals.createCode.status = true"
        >
          <i class="ico--create"></i>
          <span>
            {{ translate(translations, 'create') }}
          </span>
        </button>
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <tbl
        v-else
        ref="tbl"
        :data-loading="isDataLoading"
      >
        <template slot="head">
          <tbl-td
            v-if="checkCol('id', tblSetting)"
            name="no"
            :sort="true"
            @click.native="$_sort('id')"
          >
            {{ translate(translations, 'no') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('created_at', tblSetting)"
            name="created-date"
            :sort="true"
            @click.native="$_sort('created_at')"
          >
            {{ translate(translations, 'created date') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('code', tblSetting)"
            name="code"
            :sort="true"
            @click.native="$_sort('code')"
          >
            {{ translate(translations, 'code') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('code_name', tblSetting)"
            name="code-name"
            :sort="true"
            @click.native="$_sort('name')"
          >
            {{ translate(translations, 'code name') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('users_total', tblSetting)"
            name="users-total"
            :sort="true"
            @click.native="$_sort('users_total')"
          >
            {{ translate(translations, 'total users') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('users_confirm', tblSetting)"
            name="users-confirm"
            :sort="true"
            @click.native="$_sort('users_confirm')"
          >
            {{ translate(translations, 'users confirm') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('users_reject', tblSetting)"
            name="users-reject"
            :sort="true"
            @click.native="$_sort('users_reject')"
          >
            {{ translate(translations, 'users reject') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('users_ban', tblSetting)"
            name="users-ban"
            :sort="true"
            @click.native="$_sort('users_ban')"
          >
            {{ translate(translations, 'users ban') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('users_delete', tblSetting)"
            name="users-delete"
            :sort="true"
            @click.native="$_sort('users_delete')"
          >
            {{ translate(translations, 'users delete') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row
            v-for="(code, index) in codes"
            :key="code.id"
            :class="{
              'is-inactive': code.inactive,
              'is-deleted': code.deleted_at
            }"
            @click.native="showModifyPopup(code)"
          >
            <tbl-td
              v-if="checkCol('id', tblSetting)"
              name="no"
            >
              {{ generateNumber(index, params.page, params.rows, params.sort, countCodes || 0) }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('created_at', tblSetting)"
              name="created-date"
            >
              {{ code.created_at | date }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('code', tblSetting)"
              name="code"
            >
              {{ code.code }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('code_name', tblSetting)"
              name="code-name"
            >
              {{ code.name }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('users_total', tblSetting)"
              name="users-total"
            >
              {{ code.users_total }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('users_confirm', tblSetting)"
              name="users-confirm"
            >
              {{ code.users_confirm }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('users_reject', tblSetting)"
              name="users-reject"
            >
              {{ code.users_reject }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('users_ban', tblSetting)"
              name="users-ban"
            >
              {{ code.users_ban }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('users_delete', tblSetting)"
              name="users-delete"
            >
              {{ code.users_delete }}
            </tbl-td>
          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            v-model="params.page"
            :total="countCodes"
            :rows="params.rows"
            @page="$_trigger"
          />
        </template>
      </tbl>
    </div>

    <table-settings
      v-if="modConfig.tableConfig"
      :translations="translations"
      :show-tbl-settings="showTblSettings"
      :all-cols="allCols"
      :tbl-setting="tblSetting"
      :activate="showTblSettings"
      @activate="showTblSettings = $event"
      @load="showLoad()"
    />

    <modal
      v-if="modals.createCode.status"
      :title="translate(translations, 'create code')"
      :activate="modals.createCode.status"
      class="modal--create-code"
      @activate="modals.createCode.status = $event"
    >
      <template slot="body">
        <div class="form__item">
          <div class="form__label">
            {{ translate(translations, 'code') }}
          </div>

          <div class="form__data">
            <div class="input-wrap">
              <input
                v-model="modals.createCode.data.code"
                v-focus
                type="text"
              >
            </div>
          </div>
        </div>

        <div class="form__item">
          <div class="form__label">
            {{ translate(translations, 'code name') }}
          </div>

          <div class="form__data">
            <div class="input-wrap">
              <input
                v-model="modals.createCode.data.name"
                type="text"
              >
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          class="btn--confirm"
          @click="handleClickCreateCode"
        >
          <span>
            {{ translate(translations, 'create') }}
          </span>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= mixins
import { checkAll } from '@/assets/js/mixins/base/CheckAll'

//= components
import Pagination from '@/components/base/Pagination'
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'

//= translation
import { translations } from '@/assets/js/translations/Codes'

import focus from '@/assets/js/directives/Focus'

import _get from 'lodash/get'

export default {
  name: 'Codes',

  components: {
    Pagination,
    Modal,
    Search,
    CheckDataList
  },

  directives: {
    focus
  },

  mixins: [
    checkAll
  ],

  data () {
    const params = Object.assign({
      rows: 50,
      page: 1,
      filter_by: '',
      q: '',
      sort_by: 'id',
      sort: 'desc'
    }, this.$route.query)

    return {
      translations,
      params,
      codes: [],
      showCreate: false,

      showDataLoading: false,
      isEmpty: false,
      isDataLoading: true,
      showTblSettings: false,

      selected: [],
      selectAll: false,

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        },

        {
          value: 'updated_at',
          display: 'updated date'
        },

        {
          value: 'deleted_at',
          display: 'deleted date'
        }
      ],

      modals: {
        createCode: {
          status: false,
          data: {
            code: undefined,
            name: undefined
          },
          reset () {
            this.status = false
            this.data.code = undefined
            this.data.name = undefined
          }
        }
      },

      filterBy: [
        {
          value: 'code',
          display: 'code'
        },

        {
          value: 'name',
          display: 'code name'
        }

      ],

      tblSetting: 'codes_list',

      allCols: [
        {
          value: 'id',
          display: 'no'
        },
        {
          value: 'created_at',
          display: 'created date'
        },
        {
          value: 'code',
          display: 'code'
        },

        {
          value: 'code_name',
          display: 'code name'
        },
        {
          value: 'users_total',
          display: 'total users'
        },
        {
          value: 'users_confirm',
          display: 'users confirm'
        },
        {
          value: 'users_reject',
          display: 'users reject'
        },
        {
          value: 'users_ban',
          display: 'users ban'
        },
        {
          value: 'users_delete',
          display: 'users delete'
        },
        {
          value: 'agent_id',
          display: 'agent id'
        },
        {
          value: 'agent_name',
          display: 'agent name'
        },
        {
          value: 'group',
          display: 'group'
        },

        {
          value: 'bank-name',
          display: 'bank name'
        },
        {
          value: 'bank-account-name',
          display: 'bank account'
        },
        {
          value: 'bank-number',
          display: 'bank number'
        },
        {
          value: 'status',
          display: 'status'
        },
        {
          value: 'updated_at',
          display: 'updated date'
        },
        {
          value: 'deleted_at',
          display: 'deleted date'
        }
      ],

      depositAcc: {
        name: 'default',
        id: null
      },

      showModify: false,
      modifyParams: {}
    }
  },

  computed: {
    ...mapState('code', {
      allCodes: 'codes'
    }),

    ...mapState('code', {
      countCodes: 'count'
    }),

    ...mapState('agent', [
      'agents',
      'count'
    ]),

    ...mapState('deposit-account', [
      'depositAccounts'
    ])
  },

  watch: {
    '$route' (to) {
      this.route = to
      this.search(this.generate(to.query))
    },

    showCreate (popup) {
      if (popup) {
        this.getAgent({
          status: 'active',
          params: {
            rows: 30
          }
        })
      }
    },

    showModify (val) {
      if (val) {
        this.depositAcc.id = this.modifyParams.deposit_id
      }
    },

    modifyParams: {
      handler (val) {
        if (val.deposit_id !== this.depositAcc.id) {
          this.depositAcc.id = val.deposit_id
        }
      },
      deep: true
    },

    'modifyParams.deposit_id': function (id) {
      id = parseInt(id)

      this.checkDepositAccount(id)
    }
  },

  async created () {
    if (this.$route.params.status === undefined) {
      this.$router.push(`${this.$route.path}/all`)
    }

    this.params = this.generate(this.params)

    this.search()

    await this.getDeposits({
      status: 'all',
      params: { rows: 500 }
    })

    await this.getAgent({
      status: this.$route.params.status,
      params: { rows: 50, sort_by: 'id', sort: 'desc' }
    })
  },

  methods: {
    ...mapActions('code', {
      getCodes: 'get',
      createCode: 'create',
      patch: 'update'
    }),

    ...mapActions('code', [
      'recover',
      'delete'
    ]),

    ...mapActions('agent', {
      getAgent: 'get'
    }),

    ...mapActions('deposit-account', {
      getDeposits: 'get'
    }),

    handleClickCreateCode () {
      const success = async () => {
        try {
          const modal = this.modals.createCode

          await this.createCode({
            code: modal.data.code,
            name: modal.data.name
          })

          modal.reset()

          await this.search()

          return this.$showAlert({ type: 'success' })
        } catch (error) {
          const errorName = _get(error, 'response.data.name')
          const errorMessage = _get(error, 'response.data.msg')

          if (errorName === 'VALIDATION_ERROR') {
            return this.$showAlert({ type: 'error', text: errorMessage })
          }

          if (errorName === 'CREATE_ERROR') {
            if (errorMessage === 'DUPLICATE_ENTRY') {
              return this.$showAlert({ type: 'error', text: 'duplicate_code' })
            }
          }

          console.log(error)
          this.$showAlert({ type: 'error' })
          throw error
        }
      }

      return this.$showAlert({
        type: 'prompt',
        success,
        title: 'modify'
      })
    },

    checkDepositAccount (id) {
      const depositAccounts = this.depositAccounts

      const check = depositAccounts.find(e => id == e.id)

      this.checkDepositAccountActive = check.active
    },

    async search (query) {
      this.params = query || this.params

      this.selected = []
      this.selectAll = false

      // == reset flag
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }
      this.isEmpty = false

      await this.getCodes({ status: this.$route.params.status, params: this.params })
      this.codes = JSON.parse(JSON.stringify(this.allCodes))

      // == set flag
      if (!this.codes.length) {
        this.isEmpty = true
      }

      this.showDataLoading = false
      this.isDataLoading = false
    },

    showModifyPopup (data) {
      this.showModify = true
      this.modifyParams = Object.assign({}, data)
    }
  }
}
</script>
