import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: '에서',
    to: '까지',

    deleted: '삭제',
    restore_confirm: '복원(정상)',
    restore_stop: '복원(중지)',
    //= table
    edit_table_settings: '테이블 설정 수정',
    no: '번호',
    created_date: '등록일자',
    group: '계좌 그룹',
    code: '코드',
    code_name: '코드명',
    total_users: '유저 수',
    users_confirm: '정상 유저',
    users_reject: '거부 유저',
    users_ban: '중지 유저',
    users_delete: '삭제 유저',
    agent_id: '매장 아이디',
    agent_name: '매장 이름',
    bank_name: '은행명',
    bank_number: '계좌번호 ',
    bank_account: '예금주',
    bank_type: '은행 종류',
    status: '상태',
    updated_date: '수정일자',
    deleted_date: '삭제',

    //= create code
    create_code: '등록 코드',
    agent: '매장',
    deposit_name: '입금 은행명',
    active: '정상',
    inactive: '중지',
    create: '등록',
    select_name: '선택 은행',

    // modify code
    modify_code: '코드 수정',
    modify: '수정',
    select_deposit_account: '선택 입금 계좌'
  }
}
