/*
  import focus from '@/assets/js/directives/Focus'

  directives: {
    focus
  }
*/

export default {
  inserted: function (el, binding) {
    if (binding.value === false) {
      return
    }

    el.focus()
  }
}
