import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,
    deleted: `削除`,
    restore_confirm: `復元(正常)`,
    restore_stop: `復元(中止)`,

    //= table
    edit_table_settings: `テーブル設定の修正`,
    no: `ナンバー`,
    created_date: `登録日時`,
    group: `口座グループ`,
    code: `コード`,
    code_name: `コード名`,
    total_users: `ユーザー数`,
    users_confirm: `正常ユーザー`,
    users_reject: `拒否ユーザー`,
    users_ban: `停止ユーザー`,
    users_delete: `削除ユーザー`,
    agent_id: `店舗 ID`,
    agent_name: `店舗名`,
    bank_name: `銀行名`,
    bank_number: `口座番号`,
    bank_account: `口座名`,
    bank_type: `銀行種類`,
    status: `状態`,
    updated_date: `修正日時`,
    deleted_date: `削除`,

    //= create code
    create_code: `登録コード`,
    agent: `店舗`,
    deposit_name: `入金 銀行名`,
    code: `コード`,
    code_name: `コード名`,
    active: `正常`,
    inactive: `中止`,
    create: `登録`,
    select_name: `銀行選択`,

    // modify code
    modify_code: `コード修正`,
    modify: `修正`
  }
}
